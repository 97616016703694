@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #cd0d0d;
  --secondary: #404040;
}

/* firefox scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--secondary);
}

/* Other browser scrollbar */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--secondary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 14px;
  border: 3px solid var(--secondary);
}

html {
  @apply scroll-smooth hover:scroll-auto;
}
body {
  @apply h-screen bg-dark-250 font-sans text-gray-100 antialiased;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.quill {
  @apply bg-none shadow-md !important;
}

.ql-editor {
  @apply h-[200px] p-4 !important;
}

.ql-toolbar.ql-snow {
  @apply rounded-tl-lg rounded-tr-lg border-primary-100 bg-dark-150  py-4 !important;
}

.ql-container.ql-snow {
  @apply border-[1px]  border-primary-100 bg-dark-150 !important;
}

.ql-snow .ql-fill {
  @apply fill-light-100 !important;
}

.ql-snow .ql-stroke {
  @apply stroke-light-100 !important;
}

.ql-picker-label {
  @apply text-light-100 !important;
}

.ql-container.ql-snow {
  @apply border border-primary-100 !important;
}

.ql-editor.ql-blank::before {
  @apply text-light-300 !important;
}

.ql-snow.ql-toolbar button svg {
  @apply hover:ring-2 hover:ring-primary-100 !important;
}

.ql-toolbar.ql-snow .ql-picker-label {
  @apply hover:ring-2 hover:ring-primary-100 !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
